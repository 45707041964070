/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  background-color: #f7f9fa;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

h2 {
  margin: 0;
  font-weight: 500;
  font-size: 18px;
}

h3 {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
}

p {
  margin: 0;
}

button {
  font-family: Roboto, "Helvetica Neue", sans-serif;

  &.primary-btn {
    font-size: 14px;
    font-weight: 700;
    color: white;
    background-color: #ed0000;
    border-radius: 40px;
    border: none;
    padding: 11px 24px;
    cursor: pointer;
    width: fit-content;
    height: 40px;

    &:hover:enabled {
      color: #ed0000;
      background-color: white;
      border: solid 1px #ed0000;
    }

    &:disabled {
      background-color: #edeff0;
      cursor: not-allowed;
      color: #8a8a8a;
    }
  }
  &.secondary-btn {
    font-size: 14px;
    font-weight: 700;
    color: red;
    background-color: white;
    border-radius: 40px;
    padding: 11px 24px;
    cursor: pointer;
    width: fit-content;
    height: 40px;
    border: solid 1px #ed0000;
    display: flex;
    align-items: center;


    &:hover:enabled {
      color: white;
      background-color: #ed0000;
      border: solid 1px #ed0000;
    }

    &:disabled {
      background-color: #edeff0;
      cursor: not-allowed;
      color: #8a8a8a;
      border: solid 1px #707070;
    }
  }
  &.primary-rect-btn {
    font-size: 14px;
    font-weight: 500;
    color: white;
    background-color: #ed0000;
    border-radius: 8px;
    border: none;
    padding: 8px;
    cursor: pointer;
    width: fit-content;
    height: 30px;

    &:hover:enabled {
      color: #ed0000;
      background-color: white;
      border: solid 1px #ed0000;
    }

    &:disabled {
      background-color: #edeff0;
      cursor: not-allowed;
      color: #8a8a8a;
    }
  }
}

.mat-form-field {
  font-size: 14px;
  &:not(.mat-form-field-disabled) .mat-select-placeholder {
    color: #6c7678;
  }
}

.mat-form-field-label {
  font-size: 12px !important;
  color: #374649;
}

.mat-select-arrow {
  color: #ed0000;
}

.mat-tooltip {
  font-size: 12px !important;
}

// .cdk-overlay-pane:not(.mat-datepicker-popup .popup-class-apnnel .mat-dialog-container) {
//   width: 201px !important;
//   transform: translateX(-9px) !important;
// }

.mat-select-panel-wrap {
  width: 100%;
}

.my-panel-class,
.my-multi-select {
  margin-top: 30px !important;
  min-width: unset !important;
}
.my-panel-class-maxheight {
  margin-top: 30px !important;
 // min-width: unset !important;
  max-height: 546px !important;
}
.my-panel-class-cl,
.my-multi-select {
  margin-top: 30px !important;
  width: 200px;
  margin-left: 30px;
  min-width: 200px !important;
}

.js-plotly-plot .plotly .main-svg {
  border-radius: 16px;

  .gtitle {
    font-weight: 500 !important;
  }
}

// .mat-select-panel {
//   max-height: 328px !important;
// }
.mat-select-panel,
.optimes-container:not(:empty),
.mat-drawer {
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #6c7678;
  }
}

.section-container {
  padding: 16px;
  border-radius: 10px;
  background-color: white;
}

.mat-drawer-inner-container {
  overflow: unset !important;
}

.error-snackbar {
  background-color: orangered;
  color: white;
}

.success-snackbar {
  background-color: #40a900;
  color: white;
}

.ngx-slider {
  .ngx-slider-bar {
    background: #ffcccb !important;
  }

  .ngx-slider-selection {
    background: #ed0000 !important;
  }

  .ngx-slider-pointer {
    background: #ed0000 !important;
    border: none !important;

    &:focus-visible {
      outline: none !important;
    }

    &.ngx-slider-active:after {
      background: white !important;
    }
  }

  .ngx-slider-bubble {
    font-size: 14px !important;
  }
}

.mat-expansion-indicator::after {
  color: #ed0000;
}
.popup-class-apnnel .mat-dialog-container {
  width: 650px !important;
  height: 350px !important;
  border-radius: 16px;
}
.allocation-class-apnnel .mat-dialog-container {
  width: 650px !important;
  height: 380px !important;
  border-radius: 16px;
}
.delete-popup-class-apnnel .mat-dialog-container {
  width: 650px !important;
  height: 250px !important;
  border-radius: 16px;
}

.merge-model-popup-class-apnnel .mat-dialog-container {
  width: 800px !important;
  height: auto !important;
  border-radius: 16px;
}

.stage-popup-class-apnnel .mat-dialog-container {
  width: 650px !important;
  height: 300px !important;
  border-radius: 16px;
}
.cdk-overlay-container .cdk-overlay-pane .my-panel-class {
  width: 201px !important;
  transform: translateX(4px) !important;
}
.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 4px;
  border-color:#ed0000;
  cursor: pointer;
  &.badge-danger {
    background-color: #d10000;
  }
}
::-webkit-scrollbar {
  width: 4px !important;
  }
  ::-webkit-scrollbar-track {
  border-radius: 4px !important;
  background-color: #EFEFEF!important;
  }
  ::-webkit-scrollbar-thumb {
  border-radius: 4px !important;
  width: 5px;
  background-color: #7a7d7e !important;
  }

  .user-added-container{
    display: flex;
    width: inherit;
  }
  .user-added-container1{
    display: flex;
    flex-wrap: wrap;
    width: -webkit-fill-available;
    align-items:center;

  }
  .left-half {
    flex: 0.4;
  }
  .right-half {
    flex: 1;
    padding: 1rem;
    display: flex;
    .mat-checkbox {
      margin-right: 18px;
    }
  }
